import { Suspense, lazy } from "react";
import { RouteObject } from "react-router";

import BaseLayout from "./components/layout/BaseLayout";
import SuspenseLoader from "./components/atoms/SuspenseLoader";
import { Navigate } from "react-router-dom";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import("./components/pages/status/Status404"))
);

const HomePage = Loader(lazy(() => import("./components/pages/timeline")));

const routes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/timeline" replace />,
      },
      {
        path: "/timeline",
        element: <HomePage />,
      },
      {
        path: "/hall-of-fame",
        element: <HomePage />,
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export default routes;

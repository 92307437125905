import { useRoutes } from 'react-router-dom';
import router from './routes';
import { CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { GlobalContext } from './context/GlobalContext';


function App() {
  const content = useRoutes(router);
  const { loader } = useContext(GlobalContext);

  return (
    <>
      {content}
      {
        loader && (
          <div
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 999
            }}
            className='flex items-center justify-center w-full h-screen bg-gradient-to-r from-[#003384] via-[#5e0080] to-[#006c84] background-animate'
          >
            <CircularProgress size={64} disableShrink thickness={3} sx={{ color: "white" }} />
          </div>
        )
      }

    </>
  );
}
export default App;
